@tailwind base;
@tailwind components;
@tailwind utilities;

@import "calendar.css";
@import "text-editor.css";
@import "message-editor.css";
@import "date_range.css";
@import "mini-dashboard.css";
@import "carousel.css";
@import "date_range_picker.css";
@import "onboarding-carousel.css";
@import "get-inspired.css";
@import "time_input.css";

:root {
  --container-padding: 20px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #FFF;
}

::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #B2B2B2;
}

@layer base {
  body {
    @apply antialiased;
  }
}

#__next {
  @apply flex flex-col min-h-screen;
}

a, input, button {
  @apply touch-manipulation;
}

@font-face {
  font-family: Baskerville;
  src: local("Baskerville Display PT Regular"),
    url("../../ui/fonts/BaskervilleDisplayPT-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Baskerville;
  src: local("Baskerville Display PT Bold"),
    url("../../ui/fonts/BaskervilleDisplayPT-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../ui/fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Medium"),
    url("../../ui/fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter SemiBold"),
    url("../../ui/fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"),
    url("../../ui/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Light"),
    url("../../ui/fonts/Poppins-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"),
    url("../../ui/fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium"),
    url("../../ui/fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins SemiBold"),
    url("../../ui/fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Trebuchet MS";
  src: url("../../ui/fonts/Trebuchet-MS.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Trebuchet MS";
  src: url("../../ui/fonts/Trebuchet-MS-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

.table-head-item:first-child {
  @apply pl-0 rounded-tl-8 lg:rounded-tl-20 rounded-bl-8 lg:rounded-bl-20 lg:pl-30;
}

.table-head-item:last-child {
  @apply rounded-tr-8 lg:rounded-tr-20 rounded-br-8 lg:rounded-br-20;
}

.table-row-item:first-child {
  @apply pl-20 lg:pl-30;
}

.table-row-item:last-child {
  @apply pr-20 lg:pr-30;
}

.container-public {
  @apply max-w-[1280px] mx-auto px-container-public;
}

.overlay {
  width: 100%;
  @apply left-0 z-50;
}

blockquote {
  @apply pl-20 border-l-3 border-primary border-opacity-30 text-grey-900 text-opacity-70;
}

.fixed-admin-pagination {
  @apply fixed flex-col lg:flex-row bottom-0 right-0 left-0 lg:left-[223px] bg-white py-10 px-10 lg:px-15 gap-5;
}

.service-carousel-shadow {
  background: linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0.71) 0.01%, rgba(255, 255, 255, 0) 89.06%);
  pointer-events: none;
}

.shadow-filters {
  background: linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
  pointer-events: none;
}

.overlay-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* This will only work if everything else in the parent element is center-aligned */
.overlay-w-full {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-headless-notifier-fixed {
  z-index: 9999;
}

.search-corners::before {
  content: '';
  height: 80px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 50px;
  position: absolute;
  background-color: #fff;
  right: 100%;
}

.search-corners::after {
  content: '';
  height: 80px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 50px;
  position: absolute;
  background-color: #fff;
  left: 100%;
}

.prose h1:first-child,
h2:first-child,
h3:first-child {
  margin-top: 0 !important;
}

.notification-content a {
  @apply underline text-primary hover:text-primary-dark;
}

.notification-content.notification-read a {
  @apply text-opacity-40;
}

figure.wp-block-gallery {
  display: flex;
  gap: 10px;
}

figcaption.wp-element-caption {
  position: relative;
  margin-top: 0px;
  color: #7F7F7F;
  opacity: 0.6;
}

.message-content ul {
  list-style: disc;
  padding-left: 40px;
}

.message-content ol {
  list-style: decimal;
  padding-left: 40px;
}

.message-content .ql-align-left {
  text-align: left;
}

.message-content .ql-align-center {
  text-align: center;
}

.message-content .ql-align-right {
  text-align: right;
}

.tf-v1-widget iframe {
  border-radius: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

#nprogress .bar {
  background: #A99058 !important;
}

/* Stripe */
.StripeElement {
  @apply transition-all duration-100 ease-in border border-grey-250 p-15 rounded-4 ring-3 ring-transparent;
}

.StripeElement--focus {
  @apply ring-primary ring-opacity-30;
}

.StripeElement--invalid {
  @apply border-red ring-red ring text-red;
}